import * as React from 'react';
import { Link, PageProps } from 'gatsby';
import plural from 'plural-ru';
import qs from 'query-string';
import { toGatsbyProduct } from 'monkeypatch';
import { Layout } from 'components/Layout';
import { Loading } from 'components/Loading';
import { SEO } from 'components/SEO';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { ProductCard } from 'components/ProductCard';
import { useSearchQuery } from '__generated__/urql';

const Wrapper: React.FC = ({ children }) => (
  <Layout>
    <SEO title="Поиск" />
    <div className="pc-row pb-8 min-h-screen">
      <Breadcrumbs items={[{ label: 'Каталог', url: '/catalog' }]} />
      {children}
    </div>
  </Layout>
);

const SearchPage = ({ location }: PageProps) => {
  const params = qs.parse(location.search);
  const search = typeof params.q === 'string' ? params.q : '';

  const [res] = useSearchQuery({
    pause: !params.q,
    variables: { search },
  });

  if (res.fetching || !res.data) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );
  }

  if (res.error) {
    return (
      <Wrapper>
        <div className="text-red">Произошла ошибка</div>
      </Wrapper>
    );
  }

  const productsLength = res.data.products.length;

  if (!res.data || productsLength === 0) {
    return (
      <Wrapper>
        <h1 className="text-black text-4xl font-bold leading-snug mb-6">Ничего не нашлось</h1>
        <p className="leading-5">
          Попробуйте сформулировать запрос по-другому или вернитесь в{' '}
          <Link to="/catalog" className="text-orange underline hover:no-underline">
            каталог
          </Link>
        </p>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1 className="text-black text-4xl font-bold leading-snug mb-10">
        По запросу &ldquo;{search}&rdquo; {plural(productsLength, 'найден', 'найдены', 'найдено')} {productsLength}{' '}
        {plural(productsLength, 'продукт', 'продукта', 'продуктов')}
      </h1>
      <div className="pc-free-items">
        {res.data.products.map((p) => (
          <div className="pc-free-item" key={p.rowId}>
            <ProductCard data={toGatsbyProduct(p)} />
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export const dynamicQuery = /* urqlGraphQL */ `
  query Search($search: String!) {
    products(search: $search) {
      rowId
      ...ProductCard_data
    }
  }
`;

export default SearchPage;
